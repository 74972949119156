import { helperMixin, saveAndUpdate } from "@/mixins";
import { defineComponent } from "vue";
import fleetHttp from "@/http/fleet";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseFormApp from "@/components/Base/BaseFormApp.vue";
import TeleportToContentFooter from "@/components/TeleportToContentFooter.vue";
import { DefaultCondition, SelectionType, Tonnage } from "@/enums";
import selectionStore from "@/store/selection";
import SelectionListModal from "@/components/Selection/SelectionListModal.vue";
export default defineComponent({
    components: {
        TeleportToContentFooter,
        BaseFormGroup,
        BaseFormApp,
        SelectionListModal,
    },
    mixins: [helperMixin, saveAndUpdate(fleetHttp())],
    data() {
        return {
            SelectionType,
            DefaultCondition,
            Tonnage,
            selectionListTitle: "",
            selectionAddTitle: "",
            selectionEditTitle: "",
            selectionListType: null,
            showSelectionListModal: false,
        };
    },
    computed: {
        selectionStore,
        showCommunicationDetail() {
            var _a;
            return (((_a = this.selectionStore.findById(this.form.communication_id, this.selectionStore.state.fleet_communication)) === null || _a === void 0 ? void 0 : _a.name) === this.DefaultCondition.FleetCommunicationNeedDetail);
        },
    },
    methods: {
        onMounted() {
            return this.selectionStore.fetch();
        },
        handleShowSelectionModal(addTitle, editTitle, type) {
            this.selectionAddTitle = addTitle;
            this.selectionEditTitle = editTitle;
            this.selectionListType = type;
            this.showSelectionListModal = true;
        },
        setForm() {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28;
            this.form.ship_name = (_a = this.data) === null || _a === void 0 ? void 0 : _a.ship_name;
            this.form.call_sign = (_b = this.data) === null || _b === void 0 ? void 0 : _b.call_sign;
            this.form.ship_flag = (_c = this.data) === null || _c === void 0 ? void 0 : _c.ship_flag;
            this.form.IMO_number = (_d = this.data) === null || _d === void 0 ? void 0 : _d.IMO_number;
            this.form.type_of_ship = (_e = this.data) === null || _e === void 0 ? void 0 : _e.type_of_ship;
            this.form.classification_society = (_f = this.data) === null || _f === void 0 ? void 0 : _f.classification_society;
            this.form.ship_registry = (_g = this.data) === null || _g === void 0 ? void 0 : _g.ship_registry;
            this.form.type_of_hull = (_h = this.data) === null || _h === void 0 ? void 0 : _h.type_of_hull;
            this.form.loa = (_j = this.data) === null || _j === void 0 ? void 0 : _j.loa;
            this.form.breadth_moulded = (_k = this.data) === null || _k === void 0 ? void 0 : _k.breadth_moulded;
            this.form.depth_moulded = (_l = this.data) === null || _l === void 0 ? void 0 : _l.depth_moulded;
            this.form.keel_to_masterhead = (_m = this.data) === null || _m === void 0 ? void 0 : _m.keel_to_masterhead;
            this.form.free_board_summer = (_o = this.data) === null || _o === void 0 ? void 0 : _o.free_board_summer;
            this.form.dwt_summer_draft = (_p = this.data) === null || _p === void 0 ? void 0 : _p.dwt_summer_draft;
            this.form.displacement_summer = (_q = this.data) === null || _q === void 0 ? void 0 : _q.displacement_summer;
            this.form.tonnage = (_r = this.data) === null || _r === void 0 ? void 0 : _r.tonnage;
            this.form.communication_id = (_t = (_s = this.data) === null || _s === void 0 ? void 0 : _s.communication) === null || _t === void 0 ? void 0 : _t.id;
            this.form.communication_detail_id = (_v = (_u = this.data) === null || _u === void 0 ? void 0 : _u.communication_detail) === null || _v === void 0 ? void 0 : _v.id;
            this.form.minimum_temperature = (_w = this.data) === null || _w === void 0 ? void 0 : _w.minimum_temperature;
            this.form.discharge_pump = (_x = this.data) === null || _x === void 0 ? void 0 : _x.discharge_pump;
            this.form.propulsion = (_y = this.data) === null || _y === void 0 ? void 0 : _y.propulsion;
            this.form.max_continous_rating = (_z = this.data) === null || _z === void 0 ? void 0 : _z.max_continous_rating;
            this.form.propeller = (_0 = this.data) === null || _0 === void 0 ? void 0 : _0.propeller;
            this.form.commersial_speed = (_1 = this.data) === null || _1 === void 0 ? void 0 : _1.commersial_speed;
            this.form.owner_id = (_2 = this.data) === null || _2 === void 0 ? void 0 : _2.owner_id;
            this.form.owner_address = ((_5 = (_4 = (_3 = this.data) === null || _3 === void 0 ? void 0 : _3.owner) === null || _4 === void 0 ? void 0 : _4.fleet_person) === null || _5 === void 0 ? void 0 : _5.address) || null;
            this.form.owner_phone_number =
                ((_8 = (_7 = (_6 = this.data) === null || _6 === void 0 ? void 0 : _6.owner) === null || _7 === void 0 ? void 0 : _7.fleet_person) === null || _8 === void 0 ? void 0 : _8.phone_number) || null;
            this.form.owner_fax_number =
                ((_11 = (_10 = (_9 = this.data) === null || _9 === void 0 ? void 0 : _9.owner) === null || _10 === void 0 ? void 0 : _10.fleet_person) === null || _11 === void 0 ? void 0 : _11.fax_number) || null;
            this.form.manager_operator_id = (_12 = this.data) === null || _12 === void 0 ? void 0 : _12.manager_operator_id;
            this.form.mo_address =
                ((_15 = (_14 = (_13 = this.data) === null || _13 === void 0 ? void 0 : _13.manager_operator) === null || _14 === void 0 ? void 0 : _14.fleet_person) === null || _15 === void 0 ? void 0 : _15.address) || null;
            this.form.mo_phone_number =
                ((_18 = (_17 = (_16 = this.data) === null || _16 === void 0 ? void 0 : _16.manager_operator) === null || _17 === void 0 ? void 0 : _17.fleet_person) === null || _18 === void 0 ? void 0 : _18.phone_number) || null;
            this.form.mo_fax_number =
                ((_21 = (_20 = (_19 = this.data) === null || _19 === void 0 ? void 0 : _19.manager_operator) === null || _20 === void 0 ? void 0 : _20.fleet_person) === null || _21 === void 0 ? void 0 : _21.fax_number) || null;
            this.form.mo_telex_number =
                ((_24 = (_23 = (_22 = this.data) === null || _22 === void 0 ? void 0 : _22.manager_operator) === null || _23 === void 0 ? void 0 : _23.fleet_person) === null || _24 === void 0 ? void 0 : _24.telex_number) || null;
            this.form.mo_email =
                ((_27 = (_26 = (_25 = this.data) === null || _25 === void 0 ? void 0 : _25.manager_operator) === null || _26 === void 0 ? void 0 : _26.fleet_person) === null || _27 === void 0 ? void 0 : _27.email) || null;
            this.form.builder_hull = (_28 = this.data) === null || _28 === void 0 ? void 0 : _28.builder_hull;
        },
    },
});
